import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import {
  Promise,
  PromiseProps,
} from '@pretto/website/src/templates/home/homeBlocs/rows/rowPromises/components/Promise/Promise'

import styled from 'styled-components'

export interface RowPromisesProps {
  promises: PromiseProps[]
}

export const RowPromises: React.FC<RowPromisesProps> = ({ promises, ...props }) => (
  <RowPromisesContainer {...props}>
    <Promises>
      {promises.map(promise => (
        <Promise key={promise.title} {...promise} />
      ))}
    </Promises>
  </RowPromisesContainer>
)

const RowPromisesContainer = styled.div`
  ${grid()};
  width: 100%;
  padding: ${g(4)} 0 ${g(5)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} 0 ${g(5)}0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(7)} 0 ${g(8)} 0;
  }
`

const Promises = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    flex-direction: row;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`
